import React, { useRef, useState } from "react";
import { addNewsletter } from "../redux/actions/newsletter";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import
{
    formName,
    buttonName,
    defaultCountry,
    validateEmail,
    validatePhoneNumbers,
    serverAPI,
    validateName,
} from "../utils/envConfig";
import InputContact from "./Inputs/InputContact";
import InputEmail from "./Inputs/InputEmail";
import { useRouter } from "next/router";
import axios from "axios";
import AgreeToTerms from "./AgreeToTerms";
import ReCAPTCHA from 'react-google-recaptcha';
import dynamic from "next/dynamic";

// Lazy load the CookieCond component
const CookieCond = dynamic( () => import( '../pages/cookieconsent-config' ), {
    ssr: true,
} );

const Newsletter = () =>
{
    const dispatch = useDispatch();
    const router = useRouter();
    const [ fullName, setFullName ] = useState( null );
    const [ phone, setPhone ] = useState( null );
    const [ email, setEmail ] = useState( null );
    const [ errors, setErrors ] = useState( {} );
    const [ subscribing, setSubscribing ] = useState( false );
    const [ errorMessage, setErrorMessage ] = useState( "" );
    const [ isAgreed, setIsAgreed ] = useState( true );
    const [ selectedCountry, setSelectedCountry ] = useState( defaultCountry );
    // const [captchaVerified, setCaptchaVerified] = useState(false);
    // const recaptcha = useRef(null);

    const handleErrors = ( name, err ) =>
    {
        setErrors( ( prev ) => ( {
            ...prev,
            [ name ]: err,
        } ) );
    };

    // const onCaptchaChange = async (token) => {
    //     if (token) {
    //         const tokenTimestamp = Date.now();
    //         const tokenData = JSON.stringify({ token, tokenTimestamp });
    //         try {
    //             const res = await axios.post(`${serverAPI}/captcha/verify-captcha-token`, tokenData, {
    //                 headers: { "Content-Type": "application/json" },
    //             });
    //             if (res?.data?.status === true) {
    //                 setCaptchaVerified(true);
    //                 handleErrors("captcha", null || '');
    //             } else {
    //                 setCaptchaVerified(false);
    //                 handleErrors("captcha", "Error verifying captcha");
    //             }
    //         } catch (error) {
    //             setCaptchaVerified(false);
    //             handleErrors("captcha", "Error verifying captcha", error);
    //         }
    //     } else {
    //         setCaptchaVerified(false);
    //         handleErrors("captcha", "Captcha token is not available");
    //     }
    // };

    const handleSubmit = async ( e ) =>
    {
        e.preventDefault();
        let isError = false;
        const isValidateName = validateName( fullName?.trim() );
        if ( isValidateName !== true || errors?.name )
        {
            isError = true;
            handleErrors(
                "name",
                errors?.name
                    ? 'Enter Valid Name'
                    : isValidateName
            );
        }
        const isValidEmail = validateEmail( email );
        if ( !isValidEmail || errors?.email )
        {
            isError = true;
            handleErrors(
                "email",
                errors?.email ?? !email?.trim()?.length
                    ? "Email is required"
                    : "Invalid email"
            );
        }

        let validPhone = phone?.trim();
        if ( validPhone?.length )
        {
            validPhone = validPhone.replace( /^0+/, '' );
            const isValidContact = validatePhoneNumbers( {
                cca2: selectedCountry?.cca2,
                code: selectedCountry?.code,
                phone: validPhone,
            } );
            if ( !isValidContact || errors?.phone )
            {
                isError = true;
                handleErrors( "phone", "Invalid phone number" );
            }
        }
        if ( !isAgreed )
        {
            isError = true;
            alert( "Please agree to terms and conditions" );
        }
        // if (!captchaVerified) {
        //     isError = true;
        //     handleErrors("captcha", "Please complete the captcha verification");
        // }else{
        //     handleErrors("captcha", null || '');
        // }
        if ( isError ) return;
        setSubscribing( true );

        const formData = JSON.stringify( {
            fullName,
            phone: `${ selectedCountry?.code }${ validPhone }`,
            email,
            subscribe: true,
            cca2: selectedCountry?.cca2,
        } );
        try
        {
            // dispatch(addNewsletter(formData));
            const res = await axios.post( `${ serverAPI }/newsletter`, formData, {
                headers: { "Content-Type": "application/json" },
            } );

            window.location.href = '/thank-you';
        } catch ( err )
        {
            setErrorMessage(
                err?.response?.data?.message ?? "Internal server error"
            );
            setTimeout( () =>
            {
                setErrorMessage( "" );
            }, 5000 );
            console.log( err );
        } finally
        {
            setSubscribing( false );
        }
    };

    return (
        <>
            <CookieCond />
            <div className="bg-[#E3EFFA] px-4 pt-[1rem] pb-2 2xl:mb-44 4xs:mb-12  lg:mb-36 
         2xl:mt-9 container mx-auto 4xs:max-w-[280px] 
            2.5xs:max-w-[300px] 2xs:max-w-[400px] ixs:max-w-[380px]
            md:max-w-[650px] 3xl:max-w-[1420px] lg:max-w-[800px]
            sm:max-w-[550px] max-w-[1420px] 2xl:max-w-[1270px] xl:max-w-[1190px] rounded-3xl">
                <div className="container mx-auto 2xl:max-w-[70%]">
                    <div className="flex justify-center my-6 pt-2 pb-2">
                        <h3 className="xl:text-4xl text-2xl text-navy font-Lexend font-bold">
                            <b>Get Notified of Our Latest Properties</b>
                        </h3>
                    </div>
                    <form
                        id={ formName.newsletter }
                        onSubmit={ handleSubmit }
                        className=""
                    >
                        <div className="lg:grid lg:grid-cols-1 lg:gap-4 mx-auto lg:mx-20">
                            <div className="mb-2 lg:mb-0 col-span-1">
                                <div>
                                    <input
                                        value={ fullName }
                                        onChange={ ( e ) =>
                                            setFullName( e.target.value )
                                        }
                                        onFocus={ () => handleErrors( "name", "" ) }
                                        name="fullName"
                                        id="fullName"
                                        type="text"
                                        placeholder="Your Full Name"
                                        className=" border-none shadow-customnavy text-sm lg:text-xl rounded-full h-16 pl-9 w-full
                                     mb4 lg:mb-0   "
                                        required={ true }
                                    />
                                </div>
                                { errors?.name && (
                                    <div className="text-red-500 text-sm">
                                        { errors?.name }
                                    </div>
                                ) }
                            </div>
                            <div className="col-span-1">
                                <InputContact
                                    containerClasses={
                                        "!bg-white   !rounded-full border-none shadow-customnavy h-16 pl-4 !w-full text-sm lg:text-xl text-gray-500"
                                    }
                                    onSelectCountry={ ( ctr ) =>
                                        setSelectedCountry( ctr )
                                    }
                                    country={ selectedCountry }
                                    onInputChange={ ( txt ) => setPhone( txt ) }
                                    onError={ ( er ) => handleErrors( "phone", er ) }

                                    inputProps={ {
                                        placeholder: "Your Phone",
                                        className:
                                            "border-l-white border-l-2 ml-1",
                                        autoComplete: "tel",
                                        value: phone,
                                        required: true
                                    } }
                                />
                                { errors?.phone && (
                                    <div className="text-red-500 text-sm">
                                        { errors?.phone }
                                    </div>
                                ) }
                            </div>
                        </div>
                        <div className="relative rounded-md flex flex-col lg:flex-row justifycenter mt-4 mx-auto lg:mx-20  ">
                            <InputEmail
                                onInputChange={ ( txt ) => setEmail( txt ) }
                                onError={ ( err ) => handleErrors( "email", err ) }
                                inputProps={ {
                                    value: email,
                                    placeholder: "Your Email",
                                    className:
                                        "text-sm lg:text-xl rounded-full border-none shadow-customnavy h-16 pl-9 !w-full  ",
                                    autoComplete: "email",
                                    type: "email",
                                    required: true,
                                    id: "email",
                                } }
                                containerClassNames="w-full"
                            />

                            <div className="lg:absolute lg:top-full w-full sm:flex justify-between items-center gap-x-2 mt-3">
                                <div>
                                    { errors?.email && (
                                        <div className="text-red-500 text-sm z-50 ">
                                            { errors?.email }
                                        </div>
                                    ) }
                                </div>


                            </div>


                        </div>
                        <div className="flex 4xs:items-start 4xs:justify-start  lg:justify-center 2xl:mx-24 mt-3">
                            <AgreeToTerms
                                agreed={ isAgreed }
                                onClick={ () => setIsAgreed( ( prev ) => !prev ) }
                                className=" my-1"
                            />
                        </div>
                        <div>
                            {/* <div className="flex 4xs:items-start 4xs:justify-start  lg:justify-center 2xl:mx-24 mt-3">
                        <ReCAPTCHA
                            size="normal"
                            sitekey="6LfvfxsqAAAAAFT92Mg1RM5hF2LYjloy5lPZcphS"
                            onChange={onCaptchaChange}
                            ref={recaptcha}
                        />
                        
                    </div> */}
                            <div className="flex 4xs:items-start 4xs:justify-start  lg:justify-center 2xl:mx-24 mt-3">
                                <div>
                                    { errors?.captcha && (
                                        <div className="text-red-500 text-sm">
                                            { errors?.captcha }
                                        </div>
                                    ) }
                                </div>

                            </div>
                        </div>
                        <div className="flex justify-center  mt-3 mb-3">
                            <button
                                id={ buttonName.subscribe }
                                type="submit"
                                className="px-10 py-3 text-xl bg-night rounded-full text-[#FFDEAB] hover:bg-[#DFC090] hover:text-night my-3
                          relative block overflow-hidden font-bold font-Lexend"
                            >
                                { subscribing ? (
                                    <span className="flex items-center justify-center">
                                        <svg
                                            role="status"
                                            className="inline mx-1 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-red-600"
                                            viewBox="0 0 100 101"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="currentColor"
                                            />
                                            <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentFill"
                                            />
                                        </svg>
                                        <span>Please wait</span>
                                    </span>
                                ) : (
                                    "Subscribe"
                                ) }
                            </button>
                        </div>

                    </form>
                    {/* { errorMessage && (
                        <div className="text-red-500 text-center text-lg font-bold">
                            { errorMessage }
                        </div>
                    ) } */}
                </div>
            </div>
        </>
    );
};

export default Newsletter;
